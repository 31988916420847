<template>
<div id="app" class="px-0 py-0 mx-0 my-0 container-fluid" style="position: absolute; left: 0; top: 0; bottom: 0; right: 0;">
  
  <div class="row py-auto no-print border-bottom" style="height: 50px; background: #f7f7f7;">
    <div class="col-12 d-flex h-100 justify-content-between align-items-center">
      
      
      <div class="ms-2">
      <button type="button" class="btn btn-outline-secondary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#dataModal"><i class="bi bi-file-earmark me-2"></i>Data</button>
      <button @click="newFile" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-file-earmark-plus me-2"></i>New</button>
      <button type="button" class="btn btn-outline-secondary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="bi bi-file-earmark-minus me-2"></i>Delete</button>
      <button @click="copy(id)" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-files me-2"></i>Copy</button>
      <button type="button" class="btn btn-outline-secondary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#renameModal">Rename</button>
      <button @click="save" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-save me-2"></i>Save</button>
      <button @click="print" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-printer me-2"></i>Print</button>
      <div class="btn-group" role="group">
        <button id="btnGroup" type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          {{ name }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="btnGroup">
          <li v-for="mind in minds" :key="mind.id">
            <a class="dropdown-item" href="#" @click="open(mind.id)"><i class="bi bi-filetype-json me-2"></i>{{ mind.name }}</a>
          </li>
        </ul>
      </div>
      </div>

      <div class="me-2">
      <button @click="add" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-node-plus me-2"></i>Add</button>
      <button @click="remove" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-node-minus me-2"></i>Remove</button>
      <button type="button" class="btn btn-outline-secondary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#colorPickerModal"><i class="bi bi-palette me-2"></i>Colors</button>
      <!--div class="btn-group me-2" role="group">
        <button id="btnGroup2" type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-palette me-2"></i>Color
        </button>
        <ul class="dropdown-menu" aria-labelledby="btnGroup2">
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#007bff')"><div class="text-center btn-primary">#007bff</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#ffc107')"><div class="text-center btn-warning">#ffc107</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#dc3545')"><div class="text-center btn-danger">#dc3545</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#198754')"><div class="text-center btn-success">#198754</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#0dcaf0')"><div class="text-center btn-info">#0dcaf0</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#f8f9fa')"><div class="text-center btn-light">#f8f9fa</div></a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="changeBackgroundColor('#6c757d')"><div class="text-center btn-secondary">#6c757d</div></a>
          </li>
        </ul>
      </div-->
      <button @click="zoomIn" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-zoom-in me-2"></i>Zoom in</button>
      <button @click="zoomOut" type="button" class="btn btn-outline-secondary btn-sm me-2"><i class="bi bi-zoom-out me-2"></i>Zoom out</button>
      <button @click="screenshot" type="button" class="btn btn-outline-secondary btn-sm"><i class="bi bi-camera me-2"></i>Screenshot</button>
      </div>

    </div>
  </div>

  <div class="row mx-0 my-0 px-0 py-0 overflow-auto" style="position: absolute; left: 0; top: 50px; bottom: 0; right: 0;">
    <div class="col-12 mx-0 my-0 px-0 py-0 h-100 overflow-auto">
      <js-mind :mind="mind" :options="options" ref="jsMind" class="h-100 mx-0 my-o px-0 py-0 overflow-auto"></js-mind>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="renameModal" tabindex="-1" aria-labelledby="renameModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="renameModalLabel">Rename</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <input type="text" class="form-control" v-model="name">
            </div>
            <!--div class="col-4">
              <button class="btn btn-primary btn-block" type="button" @click="setName()">go</button>
            </div-->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="setName(id, name)" data-bs-dismiss="modal">Save changes</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="dataModal" tabindex="-1" aria-labelledby="dataModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dataModalLabel">Data</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
          
            <label for="row1" class="form-label">Download / Upload single data</label>

            <div class="row mb-3">
              <div class="col-3">
                <button @click="downloadFile" type="button" class="btn btn-outline-secondary btn-sm">Download</button>
              </div>
              <div class="col-9">
                <div class="input-group input-group-sm">
                  <input type="file" class="form-control" id="upload-input" aria-describedby="inputGroupFileAddon04" aria-label="Upload" v-on:change="onUploadFileChange">
                  <button class="btn btn-outline-secondary" type="button" @click="uploadFile">Upload</button>
                </div>
              </div>
            </div>
          
            <label for="row2" class="form-label">Backup / Restore whole data</label>

            <div class="row">
              <div class="col-3">
                <button @click="backup" type="button" class="btn btn-outline-secondary btn-sm">Backup</button>
              </div>
              <div class="col-9">
                <div class="input-group input-group-sm">
                  <input type="file" class="form-control" id="restore-input" aria-describedby="inputGroupFileAddon04" aria-label="Restore" v-on:change="onRestoreFileChange">
                  <button class="btn btn-outline-secondary" type="button" @click="restore">Restore</button>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteModalLabel">Delete</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Delete {{name}} ?
        </div>
        <div class="modal-footer">
          <button @click="del(id)" type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="colorPickerModal" tabindex="-1" aria-labelledby="colorPickerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="colorPickerModalLabel">Color Picker</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <swatches-picker v-model="colors"></chrome-picker>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
//import 'bootstrap/dist/js/bootstrap.bundle.js';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
import { Swatches } from 'vue-color'
import JsMind from './components/JsMind.vue';
import Dexie from 'dexie';
import { readAsText } from './libs/promise-file-reader';

const DEFAULT_COLOR = {
  hex: '#FFFFFF',
  hsl: { h: 0, s: 0, l: 1, a: 1 },
  hsv: { h: 0, s: 0, v: 1, a: 1 },
  rgba: { r: 255, g: 255, b: 255, a: 1 },
  a: 1
}

export default {
  name: 'App',
  components: {
    JsMind,
    //'chrome-picker': Chrome,
    'swatches-picker': Swatches
  },
  data: function() {
    return {
      mind: null,
      options: {
        container: 'jsmind_container',
        theme: 'default',
        editable: true,
      },
      colors: {},
      jsMind: null,
      minds: {},
      id: '',
      name: 'none',
      author: 'none',
      version: '1.0',
      db: new Dexie("mindmap"),
      modal: {
        file: {
          upload: null,
          restore: null,
        }
      },
    }
  },
  methods: {
    add() {
      this.jsMind.add();
    },
    remove() {
      this.jsMind.remove();
    },
    beginEdit() {
      this.jsMind.beginEdit();
    },
    screenshot() {
      this.jsMind.screenshot();
    },
    move() {
      this.jsMind.move();
    },
    zoomIn() {
      this.jsMind.zoomIn();
    },
    zoomOut() {
      this.jsMind.zoomOut();
    },
    changeBackgroundColor(color) {
      this.jsMind.changeBackgroundColor(color);
    },
    /*uploadFile() {
      self = this;
      this.jsMind.openFile(async function() {
        let id = Math.random().toString(36).substring(7);
        let data = self.jsMind.getData();
        let name = data.meta.name;
        await self.db.mindmaps.put({
          id: id, name: name, data: data,
        });
        self.minds = await self.db.mindmaps.orderBy('name').toArray();
        self.name = name;
        self.id = id;
        Modal.getInstance(document.getElementById('fileModal')).hide();
      });
    },*/
    async uploadFile() {
      if(!this.modal.file.upload) {
        return;
      }
      const upload = await readAsText(this.modal.file.upload);
      const data = JSON.parse(upload);
      const id = Math.random().toString(36).substring(7);
      const name = data.meta.name;
      await self.db.mindmaps.put({
          id: id, name: name, data: data,
        });
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
      Modal.getInstance(document.getElementById('dataModal')).hide();
      //self = this;
      /*this.jsMind.upload(this.modal.file.upload, asynd function() {
        let id = Math.random().toString(36).substring(7);
        let data = self.jsMind.getData();
        let name = data.meta.name;
        await self.db.mindmaps.put({
          id: id, name: name, data: data,
        });
        self.minds = await self.db.mindmaps.orderBy('name').toArray();
        self.name = name;
        self.id = id;
        Modal.getInstance(document.getElementById('fileModal')).hide();
      });*/
    },
    onUploadFileChange(e) {
      this.modal.file.upload = e.target.files[0];
    },
    downloadFile() {
      this.jsMind.saveFile();
      Modal.getInstance(document.getElementById('dataModal')).hide();
    },
    async backup() {
      const data = await this.db.mindmaps.orderBy('name').toArray();
      this.jsMind.backup(data);
      Modal.getInstance(document.getElementById('dataModal')).hide();
    },
    onRestoreFileChange(e) {
      this.modal.file.restore = e.target.files[0];
    },
    async restore() {
      if(!this.modal.file.restore) {
        return;
      }
      const restore = await readAsText(this.modal.file.restore);
      const mindmaps = JSON.parse(restore);
      for(let mindmap of mindmaps) {
        await this.db.mindmaps.add(mindmap);
      }
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
      Modal.getInstance(document.getElementById('dataModal')).hide();
    },
    async newFile() {
      let id = Math.random().toString(36).substring(7);
      let mind = {"meta":{"name":"new_mindmap","author":"hoge@hoge.com","version":"0.4.6"},"format":"node_tree","data":{"id":"root","topic":"jsMind Example","expanded":true}};
      let name = 'new mindmap';
      await this.db.mindmaps.put({
        id: id, name: name, data: mind,
      });
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
      this.name = name;
      this.mind = mind;
      this.id = id;
    },
    async save() {
      //this.jsMind.save();
      let self = this;
      let data = this.jsMind.getData();
      await this.db.mindmaps.put({
        id: self.id, name: self.name, data: data,
      });
    },
    open(id) {
      this.id = id;
      //this.close();
    },
    async copy(id) {
      if(!id) {
        return;
      }
      let row = await this.db.mindmaps.get(id);
      let name = row.data.meta.name + 'のコピー';
      row.data.meta.name = name;
      let newId = Math.random().toString(36).substring(7);
      await this.db.mindmaps.put({
        id: newId, name: name, data: row.data,
      });
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
      this.id = newId;
    },
    async del(id) {
      if(!id) {
        return;
      }
      await this.db.mindmaps.delete(id);
      this.id = '';
      this.name = 'none';
      this.mind = {};
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
    },
    print() {
      window.print();
    },
    test2() {
      let myModalEl = document.getElementById('openModal');
      let modal = Modal.getInstance(myModalEl);
      modal.show();
    },
    close() {
      let myModalEl = document.getElementById('openModal');
      let modal = Modal.getInstance(myModalEl);
      modal.hide();
    },
    setLink() {

    },
    async setName(id, name) {
      if(!id || !name) {
        return;
      }
      let row = await this.db.mindmaps.get(id);
      row.data.meta.name = name;
      await this.db.mindmaps.put({
        id: id, name: name, data: row.data,
      });
      row = await this.db.mindmaps.get(id);
      this.mind = row.data;
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
    },
    async setAuthor(id, author) {
      if(!id || !name) {
        return;
      }
      let row = await this.db.mindmaps.get(id);
      row.data.meta.author = author;
      await this.db.mindmaps.put({
        id: id, data: row.data,
      });
      row = await this.db.mindmaps.get(i);
      this.mind = row.data;
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
    },
    async setVersion(id, version) {
      if(!id || !version) {
        return;
      }
      let row = await this.db.mindmaps.get(id);
      row.data.meta.version = version;
      await this.db.mindmaps.put({
        id: id, data: row.data,
      });
      row = await this.db.mindmaps.get(i);
      this.mind = row.data;
      this.minds = await this.db.mindmaps.orderBy('name').toArray();
    },
  },
  computed: {

  },
  mounted: async function() {
    this.jsMind = this.$refs.jsMind;
    //new Modal(document.getElementById('exampleModal'), {keyboard: false});
    this.db.version(1).stores({
      mindmaps: "id, name"
    });
    this.minds = await this.db.mindmaps.orderBy('name').toArray();
    self = this;
    document.onkeydown = function(event) {
	    if(event) {
        let key = event.which || event.keyCode;
        let ctrl = event.ctrlKey ? event.ctrlKey : ((key === 17) ? true : false);
        if(ctrl && event.keyCode === 83) {
          event.preventDefault();
          self.save();
        } else if(ctrl && event.keyCode === 65) {
          event.preventDefault();
          self.add();
        } else if(event.keyCode === 112) {
          event.preventDefault();
          Modal.getOrCreateInstance(document.getElementById('colorPickerModal')).show();
        } else if(event.keyCode === 113) {
          event.preventDefault();
          self.beginEdit();
        } else if(event.keyCode === 46) { // del
          alert(event.keyCode);
        }
	    }
    };
    let modal = document.getElementById('colorPickerModal')
    modal.addEventListener('show.bs.modal', function (event) {
      self.colors = DEFAULT_COLOR;
    });
    modal.addEventListener('hidden.bs.modal', function (event) {
      self.jsMind.focus();
    });
  },
  watch: {
    id: async function(newVal, oldVal) {
      if(!newVal) {
        return;
      }
      let mindmap = await this.db.mindmaps.get(newVal);
      this.name = mindmap.name;
      this.mind = mindmap.data;
      document.title = mindmap.name;
    },
    colors: function(newVal, oldVal) {
      this.changeBackgroundColor(newVal.hex);
      Modal.getInstance(document.getElementById('colorPickerModal')).hide();
    },
  },
}
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css';
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
body {
  -webkit-print-color-adjust: exact;
}
@media print {
  .no-print {
    display: none;
  }
  body {
    zoom: 1;
    -webkit-print-color-adjust: exact;
    overflow: visible;
  }
}
</style>
