import Vue from 'vue'
import App from './App.vue'

import 'jsmind/style/jsmind.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//import './libs/jsmind.js';
//import './libs/jsmind.draggable.js';
//import './libs/jsmind.screenshot.js'

Vue.config.productionTip = false

//new jsMind({
  //container: 'jsmind_container',
  //theme: 'greensea',
  //editable: true,
//});
//console.log(window);

new Vue({
  render: h => h(App),
}).$mount('#app')
