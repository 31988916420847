<template>
  <div id="jsmind_container" :key="refresh" class="overflow-auto" style="height: 100%; box-sizing: border-box;"></div>
</template>

<script>
import '../libs/jsmind/jsmind.js';
import '../libs/jsmind/jsmind.draggable.js';
import '../libs/jsmind/jsmind.screenshot.js';
import Color from 'color';

export default {
  name: 'JsMind',
  props: {
    mind: {
      type: Object,
    },
    options: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  data: function() {
    return {
      jm: null,
      refresh: 1,
      defaultOptions: {},
    }
  },
  methods: {
    flash() {
      this.$nextTick(()=>{
        this.refresh++;
      });
    },
    add() {
      let selectedNode = this.jm.get_selected_node(); // as parent of new node
      if(!selectedNode) {
        console.log('please select a node first.');
        return;
      }
      let nodeId = jsMind.util.uuid.newid();
      let topic = '* Node_'+nodeId.substr(nodeId.length-6)+' *';
      let node = this.jm.add_node(selectedNode, nodeId, topic);
      this.save();
    },
    remove() {
      let selectedId = this.getSelectedNodeId();
      if(!selectedId) {
        console.log('please select a node first.');
        return;
      }
      this.jm.remove_node(selectedId);
    },
    beginEdit() {
      let selectedNode = this.jm.get_selected_node();
      if(!selectedNode) {
        console.log('please select a node first.');
        return;
      }
      this.jm.begin_edit(selectedNode);
    },
    focus() {
      this.jm.view.e_panel.focus();
    },
    move() {
      // move a node before another
      this.jm.move_node('other','open');
    },
    screenshot() {
      this.jm.screenshot.shootDownload();
    },
    zoomIn() {
      if(this.jm.view.zoomIn()) {
        //zoomOutButton.disabled = false;
      } else {
        //zoomInButton.disabled = true;
      }
    },
    zoomOut() {
      if(this.jm.view.zoomOut()) {
        //zoomInButton.disabled = false;
      } else {
        //zoomOutButton.disabled = true;
      }
    },
    changeBackgroundColor(color) {
      let selectedId = this.getSelectedNodeId();
      if(!selectedId) {
        console.log('please select a node first.');
        return;
      }
      this.jm.set_node_color(selectedId, color, null);
      if(Color(color).isLight()) {
        this.jm.set_node_color(selectedId, null, '#000');
      } else {
        this.jm.set_node_color(selectedId, null, '#fff');
      }
    },
    getSelectedNodeId() {
      let selectedNode = this.jm.get_selected_node();
      if(!!selectedNode){
        return selectedNode.id;
      }else{
        return null;
      }
    },
    selectFile() {

    },
    /*upload(files, callback) {
      if(files.length > 0){
        let file_data = files[0];
        jsMind.util.file.read(file_data,function(jsmind_data, jsmind_name){
          let mind = jsMind.util.json.string2json(jsmind_data);
          if(!!mind){
            self.jm.show(mind);
            callback();
          }else{
            console.log('can not open this file as mindmap');
          }
        });
      }else{
        console.log('please choose a file first')
      }
    },
    openFile(callback) {
      let self = this;
      let file_input = document.getElementById('file-input');
      let files = file_input.files;
      if(files.length > 0){
        let file_data = files[0];
        jsMind.util.file.read(file_data,function(jsmind_data, jsmind_name){
          let mind = jsMind.util.json.string2json(jsmind_data);
          if(!!mind){
            self.jm.show(mind);
            callback();
          }else{
            console.log('can not open this file as mindmap');
          }
        });
      }else{
        console.log('please choose a file first')
      }
    },*/
    saveFile() {
      let data = this.jm.get_data();
      let name = data.meta.name;
      let str = jsMind.util.json.json2string(data);
      jsMind.util.file.save(str, 'text/jsmind', name + '.json');
    },
    save() {
      let data = this.jm.get_data();
      let name = data.meta.name;
      let json = jsMind.util.json.json2string(data);
      localStorage.setItem('mind', json);
      //jsMind.util.file.save(mind_str,'text/jsmind',mind_name+'.jm');
    },
    backup(data) {
      let str = jsMind.util.json.json2string(data);
      const date = new Date(Date.now());
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      const name = 'backup_'+year+month+day+hours+minutes+seconds+'.json';
      jsMind.util.file.save(str, 'text/jsmind', name);
    },
    //getJson() {
      //let data = this.jm.get_data();
      //return jsMind.util.json.json2string(data);
    //},
    getData() {
      return this.jm.get_data();
    },
    getName() {
      let data = this.jm.get_data();
      return data.meta.name;
    },
  },
  mounted: async function() {
    this.jm = new window.jsMind(this.options);
    if(this.mind) {
      this.jm.show(this.mind);
    }
    //document.getElementById('jsmind_container').style.height = document.documentElement.clientHeight + "px";
  },
  watch: {
    options: function(newVal, oldVal) {
      this.flash();
    },
    mind: {
      handler: function(newVal, oldVal) {
        if(newVal) {
          this.jm.show(newVal);
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style>
:focus {
  outline: none;
}
</style>